<template>
  <v-container fluid class="mt-5 mb-2">
    <alert-notification :message="alert" />
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :loading="loading" @update="updatePeriod" />
    <v-card class="ma-0">
      <v-card-title>
        {{ $t('prospecting.results.table.title') }}
        <v-spacer />
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('prospecting.results.table.search')"
                      single-line hide-details
        />
      </v-card-title>
      <v-data-table :headers="headers" :options="options" :items="results" :loading="loading" :search="search"
                    :hide-default-header="false" :hide-default-footer="true" dense
      >
        <template #[`body.prepend`]>
          <tr>
            <th />
            <th />
            <th />
            <th class="text-center d-block d-sm-table-cell">
              {{ total.orders }}
            </th>
            <th />
            <th class="text-center d-block d-sm-table-cell">
              {{ total.revenues }}
            </th>
            <th />
          </tr>
        </template>
        <template #[`item.discount`]="{ item }">
          <span v-if="item.amount.length > 0">{{ item.amount }}</span>
          <span v-else>{{ item.percentage }}</span>
        </template>
        <template #[`item.lastUse`]="{ item }">
          {{ formatDate(item.lastUse) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import { displayDateISO, displayHourISO, getFirstDayOfMonthDate, getTodayDate } from '@/util/DateUtil';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import PeriodSelector from '@/views/components/common/PeriodSelector';

  export default {
    name: 'ProspectingResults',
    components: { AlertNotification, PeriodSelector },
    data () {
      return {
        startDate: getFirstDayOfMonthDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        alert: '',
        loading: false,
        search: '',
        headers: [{
          text: this.$i18n.t('prospecting.results.table.locationName'),
          align: 'center',
          filterable: false,
          sortable: false,
          value: 'locationName'
        }, {
          text: this.$i18n.t('prospecting.results.table.code'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'code'
        }, {
          text: this.$i18n.t('prospecting.results.table.discount'),
          align: 'center',
          filterable: false,
          sortable: false,
          value: 'discount'
        }, {
          text: this.$i18n.t('prospecting.results.table.orders'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'orders'
        }, {
          text: this.$i18n.t('prospecting.results.table.customers'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'customers'
        }, {
          text: this.$i18n.t('prospecting.results.table.revenues'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'revenues'
        }, {
          text: this.$i18n.t('prospecting.results.table.lastUse'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'lastUse'
        }],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['lastUse'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        results: [],
        total: {}
      };
    },
    methods: {
      formatDate (date) {
        return displayDateISO(date) + ' ' + this.$i18n.t('common.time.at') + ' ' + displayHourISO(date);
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      },
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null || this.loading) {
          return;
        }

        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/prospecting/order/results?from='
            + encodeURIComponent(this.startFullDate) + '&to=' + encodeURIComponent(this.endFullDate), { timeout: 60000 });
          if (response.data !== undefined && response.data.results !== undefined) {
            this.results = response.data.results;
            this.total = response.data.total || {};
          }
          this.success();
        } catch (error) {
          await this.fail(error, this.$i18n.t('prospecting.error'));
        }
        this.loading = false;
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      }
    }
  };
</script>
